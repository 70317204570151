@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500&display=swap");

:root {
	--bg-color: #94d8e3;
	--alt-bg-color: #0000004d;
	--font-color: #ffffff;
	--alt-font-color: #222222;
	--primary-color: #607d8b;
	--secondary-color: #93B5C6;
}
.dark {
	--bg-color: #003950;
	--alt-bg-color: #0000004d;
	--font-color: #ffffff;
	--alt-font-color: #222222;
	--primary-color: #008e9f;
	--secondary-color: #00e4ff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  min-height: 100vh;
  background: var(--bg-color);
  color: var(--font-color);
  font-family: "Nunito", sans-serif;
  padding: 80px 0;
  position: relative;
  font-size: 100%;
  transition: all 0.3s linear;
}
.container {
  width: 80%;
  max-width: 900px;
  padding: 30px;
  margin: 0 auto;
    background: var(--alt-bg-color);
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 0 20px 0px #00000045;
}
@media (max-width: 768px) {
  .container {
    padding: 20px;
    width: 90%;
  }
}
.container .head {
  width: 100%;
  display: grid;
  grid-template-columns: 70% 29%;
  gap: 1%;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .container .head {
    grid-template-columns: 1fr;
    gap: 5px;
  }
}
.head input {
  padding: 13px 20px;
  outline: none;
  border: none;
  background-color: #eee;
  border-radius: 10px;
  font-size: 0.9rem;
  font-family: inherit;
}
button {
  cursor: pointer;
  background-color: var(--primary-color);
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: inherit;
  color: var(--font-color);
  transition: all 0.3s ease;
}
button:hover {
  opacity: 0.7;
}
.filter {
  margin: 0 auto 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
@media (max-width: 576px) {
  .filter {
    flex-direction: column;
  }
}
.filter button.active {
  background-color: var(--secondary-color);
  color: #333;
}
.tasks-wrapper {
  list-style: none;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--font-color);
  max-height: 400px;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .tasks-wrapper {
    padding: 0 5px;
    gap: 5px;
  }
}
.task {
  background-color: var(--primary-color);
  padding: 4px 20px 5px 30px;
  border-radius: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  text-align: left;
  transition: all 0.5s ease;
}
.task p {
  font-size: 1rem;
  text-transform: capitalize;
  flex: 1;
}
.task button {
  background-color: transparent;
  color: var(--alt-font-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  font-weight: bold;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s;
}
.task button:hover {
  color: var(--primary-color);
  background-color: var(--alt-font-color);
  opacity: 0.85;
  border-radius: 10px;
}
.tasks-wrapper .task-done {
  opacity: 0.5;
}
.tasks-wrapper .task-done p {
  text-decoration: line-through;
  font-style: italic;
}
button.btn-delete-all {
  margin-top: 50px;
  background-color: #ba0f30;
}
.no-tasks {
  color: var(--font-color);
}
.alert {
  margin-bottom: 1rem;
  width: 230px;
  height: 80px;
  display: grid;
  align-items: center;
  text-align: center;
  font-size: 0.9rem;
  color: var(--alt-font-color);
  border-radius: 0.25rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  background: #eee;
  position: absolute;
  bottom: 0;
  left: -100%;
  transition: all 0.5s cubic-bezier(0.6, -0.28, 0.74, 0.05);
  box-shadow: 0 0 20px 0px #00000045;
}
.color-container {
  position: absolute;
  width: 100px;
  height: 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: #eee;
  padding: 3px;
  gap: 3px;
  border-radius: 10px;
  z-index: 3;
}
.color-container > span {
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #00b3b3;
  outline: 0px inset #7fffd4;
  border-radius: 10px;
  height: 50px;
}
.preventClick {
  pointer-events: none;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 10px auto;
}
.github {
	font-size: 2rem;
	color: darkcyan;
}